import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

export const TerceiraPagina = ({ dashboardData }) => {
  const groupByProduto = dashboardData.reduce((acc, item) => {
    // Verifica se o product_Id não é nulo antes de processar o item
    if (item.product_id != null) {
      const produto = item.part_code;
      acc[produto] = (acc[produto] || 0) + 1; // Incrementa a contagem
    }
    return acc;
  }, {});

  const [rankingLabelsProduto, setRankingLabelsProduto] = useState([]);
  const [rankingValuesProduto, setRankingValuesProduto] = useState([]);

  useEffect(() => {
    const newArrayProdutos = Object.entries(groupByProduto)
      .map(([item, value]) => ({ produto: item, quantidade: value }))
      .sort((a, b) => {
        if (a.produto != null) return b.quantidade - a.quantidade;
      })
      .slice(0, 10)
      .reduce((acc, item) => {
        acc[item.produto] = item.quantidade;
        return acc;
      }, {});

    setRankingLabelsProduto(Object.keys(newArrayProdutos));
    setRankingValuesProduto(Object.values(newArrayProdutos));
  }, [groupByProduto]);

  const [rankingNegativoLabelsProduto, setRankingNegativoLabelsProduto] =
    useState([]);
  const [rankingNegativoValuesProduto, setRankingNegativoValuesProduto] =
    useState([]);

  useEffect(() => {
    const newArrayProdutos = Object.entries(groupByProduto)
      .map(([item, value]) => ({ produto: item, quantidade: value }))
      .sort((a, b) => a.quantidade - b.quantidade)
      .slice(0, 10)
      .reduce((acc, item) => {
        acc[item.produto] = item.quantidade;
        return acc;
      }, {});

    setRankingNegativoLabelsProduto(Object.keys(newArrayProdutos));
    setRankingNegativoValuesProduto(Object.values(newArrayProdutos));
  }, [groupByProduto]);

  const dataRankingMaisProduzidos = {
    labels: rankingLabelsProduto,
    datasets: [
      {
        label: "Produtos mais produzidos",
        data: rankingValuesProduto,
        borderWidth: 1,
        backgroundColor: [
          "#506FD980", // Cor para a quarta fatia
        ],
        borderColor: [
          "#506FD9", // Cor para a quarta fatia
        ],
      },
    ],
  };

  const optionsRankingMaisProduzidos = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "x",

    plugins: {
      legent: { display: true },
      title: { display: true, text: "Mais produzidos" },
    },
    scales: {
      y: { title: { display: true, text: "Quantidade" }, beginAtZero: true },
      x: { title: { display: true, text: "Cor" } },
    },
  };

  const dataRankingMenosProduzidos = {
    labels: rankingNegativoLabelsProduto,
    datasets: [
      {
        label: "Produtos menos produzidos",
        data: rankingNegativoValuesProduto,
        borderWidth: 1,
        backgroundColor: [
          "#506FD980", // Cor para a quarta fatia
        ],
        borderColor: [
          "#506FD9", // Cor para a quarta fatia
        ],
      },
    ],
  };

  const optionsRankingMenosProduzidos = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "x",

    plugins: {
      legent: { display: true },
      title: { display: true, text: "Produtos menos produzidos" },
    },
    scales: {
      y: { title: { display: true, text: "Quantidade" }, beginAtZero: true },
      x: { title: { display: true, text: "Cor" } },
    },
  };
  return (
    <>
      <div style={{ width: "100%", height: "450px" }}>
        <Bar
          data={dataRankingMaisProduzidos}
          options={optionsRankingMaisProduzidos}
        />
      </div>

      
    </>
  );
};
