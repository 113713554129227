import {
  Card,
  Col,
  Form,
  ListGroup,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { userServices } from "../../apiServices/userServices";
import { useEffect, useState } from "react";

import Select from "react-select";
import { FiArrowDownCircle, FiArrowUpCircle } from "react-icons/fi";
import Decimal from "decimal.js";
import {
  apenasNumeros,
  apenasNumerosInteiros,
  formatarMoeda,
} from "../../functions/Mask";
import { CadastroEmprestimos } from "../../components/Emprestimos/Cadastro";
import { ListaEmprestimo } from "../../components/Emprestimos/Lista";

const ContaPage = () => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});

  const au = localStorage.getItem("au");

  const utp = localStorage.getItem("utp");

  const token = localStorage.getItem("token");
  const getUsers = async () => {
    const request = {
      token,
      page: 1,
      pageSearch: "client",
      searchKey: "",
      limit: 1000000,
    };
    const response = await userServices.getUsers(request);
    setUsers(
      response.data.users?.map((item) => ({
        ...item,
        value: item.ID,
        label: item.NAME,
      }))
    );
  };

  const [listaTransacoes, setListaTransacoes] = useState([]);
  const [materialIdParaListaDeTransacao, setMaterialIdParaListaDeTransacao] =
    useState(3);

  const [clientIdParaListaDeTransacao, setClientIdParaListaDeTransacao] =
    useState(null);

  const [numeroPedido, setNumeroPedido] = useState("");

  const handleChangeNumeroPedido = (value) => {
    if (value.length == 0) {
      setNumeroPedido("");
    } else {
      setNumeroPedido(value);
    }
  };

  const [modoLista, setModoLista] = useState("fabrica");

  const handleListaTransacaoDeCliente = async () => {
    const response = await userServices.listClientLastTransaction({
      client_id: modoLista == "fabrica" ? "44" : clientIdParaListaDeTransacao,
      token,
      material_id: materialIdParaListaDeTransacao,
      modo: modoLista,
      order_code: numeroPedido,
    });

    const lista = response.data.transactions;

    let total = new Decimal(0);

    setListaTransacoes(
      lista?.map((item) => {
        const amount = new Decimal(item.amount_operated);
        total = total.plus(amount);
        return {
          ...item,
          quantidade_acumulada: total.toFixed(3),
        };
      })
    );
  };

  const [somatorioSaldoClientes, setSomatorioClientes] = useState([]);
  const [somatorioSaldoFabrica, setSomatorioSaldoFabrica] = useState([]);
  useEffect(() => {
    handleListaTransacaoDeCliente();
  }, [materialIdParaListaDeTransacao, clientIdParaListaDeTransacao, modoLista]);

  const handleFetch = async () => {
    if (utp != "interno") {
      const response = await userServices.getUserData(token);
      setClientIdParaListaDeTransacao(response.data.result.USERID);
      setUser(response.data.result);
    } else {
      await getUsers();
      const responseSaldoClientes =
        await userServices.getSomatorioSaldoClientes(token);
      setSomatorioClientes(responseSaldoClientes.data.somatorio_saldo_clientes);

      const responseSaldoFabrica = await userServices.getSomatorioSaldoFabrica(
        token
      );
      setSomatorioSaldoFabrica(
        responseSaldoFabrica.data.somatorio_saldo_fabrica
      );
    }
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <div className="main main-app p-3 p-lg-4 d-flex flex-column gap-3">
      {utp === "interno" && (
        <>
          {/* Somatório de saldo de clientes */}
          <Card className="card-one ">
            <Card.Body>
              <div className="d-flex flex-column">
                <h5 className="text-primary fw-bold">
                  Saldo Total de Clientes
                </h5>
                <ListGroup variant="flush" className="mt-3">
                  {somatorioSaldoClientes &&
                    somatorioSaldoClientes.map((somatorio, index) => (
                      <ListGroup.Item
                        key={index}
                        className="d-flex justify-content-between align-items-center py-3 px-2 border-bottom"
                      >
                        <div>
                          <strong className="text-secondary">
                            {somatorio.name}
                          </strong>
                          {somatorio.name === "Ouro 18K" && (
                            <div className="small text-muted mt-1">
                              Valor pela cotação de hoje:{" "}
                              <strong className="text-success">
                                {formatarMoeda(
                                  new Decimal(apenasNumeros(au))
                                    .mul(somatorio.amount_operated)
                                    .toString()
                                )}
                              </strong>
                            </div>
                          )}
                        </div>
                        <span className="text-end">
                          {String(somatorio.amount_operated).replace(".", ",")}{" "}
                          (g)
                        </span>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </div>
            </Card.Body>
          </Card>

          {/* Somatório de saldo de fábrica */}
          <Card className="card-one ">
            <Card.Body>
              <div className="d-flex flex-column">
                <h5 className="text-primary fw-bold">Saldo Total da Fábrica</h5>
                <ListGroup variant="flush" className="mt-3">
                  {somatorioSaldoFabrica &&
                    somatorioSaldoFabrica.map((somatorio, index) =>
                      somatorio.name != null ? (
                        <ListGroup.Item
                          key={index}
                          className="d-flex justify-content-between align-items-center py-3 px-2 border-bottom"
                        >
                          <div>
                            <strong className="text-secondary">
                              {somatorio.name}
                            </strong>
                            {somatorio.name === "Ouro 18K" && (
                              <div className="small text-muted mt-1">
                                Valor pela cotação de hoje:{" "}
                                <strong
                                  className={
                                    somatorio.amount_operated < 0
                                      ? "text-danger"
                                      : "text-success"
                                  }
                                >
                                  {formatarMoeda(
                                    new Decimal(apenasNumeros(au))
                                      .mul(somatorio.amount_operated)
                                      .toString()
                                  )}
                                </strong>
                              </div>
                            )}
                          </div>
                          <span className="text-end">
                            {String(somatorio.amount_operated).replace(
                              ".",
                              ","
                            )}{" "}
                            (g)
                          </span>
                        </ListGroup.Item>
                      ) : null
                    )}
                </ListGroup>
              </div>
            </Card.Body>
          </Card>
        </>
      )}

      <Tabs
        onSelect={(e) => setModoLista(e)}
        defaultActiveKey={utp == "interno" ? "fabrica" : "cliente"}
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        {utp == "interno" && (
          <Tab eventKey="fabrica" title="Fábrica">
            <Card className="card-one">
              <Card.Body className="d-flex flex-column gap-3">
                <Form.Select
                  value={materialIdParaListaDeTransacao}
                  onChange={(e) =>
                    setMaterialIdParaListaDeTransacao(e.target.value)
                  }
                >
                  <option value="3">
                    <strong>AU - Ouro 18K</strong>
                  </option>
                  <option value="1">AG - Prata 950</option>
                </Form.Select>
                <Form.Group>
                  <Form.Label>Pesquisar</Form.Label>
                  <Form.Control
                    value={numeroPedido}
                    onChange={(e) =>
                      handleChangeNumeroPedido(
                        apenasNumerosInteiros(e.target.value)
                      )
                    }
                    onBlur={handleListaTransacaoDeCliente}
                    placeholder="Digite o número do pedido"
                  />
                </Form.Group>

                <Table bordered hover size="md">
                  <thead>
                    <tr>
                      <th>Transação</th>
                      <th>Pedido</th>
                      <th>Material</th>
                      <th>Quantidade</th>
                      {modoLista == "geral" && <th>Cliente</th>}
                      <th>Acumulado</th>
                      <th>Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listaTransacoes &&
                      listaTransacoes?.map((transaction) => (
                        <tr key={transaction.transaction_id}>
                          <td>
                            <span>
                              {transaction.amount_operated > 0 ? (
                                <FiArrowDownCircle size={24} color="green" />
                              ) : (
                                <FiArrowUpCircle size={24} color="red" />
                              )}{" "}
                              {transaction.transaction_name}
                            </span>
                          </td>
                          <td>{transaction.order_code || "—"}</td>
                          <td>{transaction.material_name}</td>
                          <td
                            style={
                              transaction.amount_operated > 0
                                ? { color: "green" }
                                : { color: "red" }
                            }
                          >
                            {Number(transaction.amount_operated)}
                          </td>
                          {modoLista == "geral" && (
                            <td>{transaction.client_name}</td>
                          )}
                          <td>{Number(transaction.accumulated)}</td>
                          <td>
                            {new Date(transaction.created_at).toLocaleString()}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Tab>
        )}
        <Tab
          className="card-one"
          eventKey="cliente"
          title={utp == "interno" && "Cliente"}
        >
          <div className="d-flex flex-column gap-3">
            <Card className="w-100 border-0">
              <Card.Body>
                {utp == "interno" && (
                  <div className="d-md-flex align-items-center justify-content-between">
                    <div className="w-100">
                      {users.length > 0 ? (
                        <Select
                          className="w-100"
                          classNamePrefix="form-control"
                          placeholder="Selecione"
                          options={users}
                          onChange={(e) =>
                            setClientIdParaListaDeTransacao(e?.ID)
                          }
                        />
                      ) : (
                        <p>Carregando opções...</p>
                      )}
                    </div>
                  </div>
                )}

                <div className="mt-3">
                  <strong>Saldo em conta:</strong>
                  <span className="ms-2">
                    {Number(listaTransacoes[0]?.accumulated || 0)} (g)
                  </span>
                </div>
              </Card.Body>
            </Card>
            <Card className="card-one">
              <Card.Body className="d-flex flex-column gap-3">
                <Form.Select
                  value={materialIdParaListaDeTransacao}
                  onChange={(e) =>
                    setMaterialIdParaListaDeTransacao(e.target.value)
                  }
                >
                  <option value="3">
                    <strong>AU - Ouro 18K</strong>
                  </option>
                  <option value="1">AG - Prata 950</option>
                </Form.Select>
                <Form.Group>
                  <Form.Label>Pesquisar</Form.Label>
                  <Form.Control
                    value={numeroPedido}
                    onChange={(e) =>
                      handleChangeNumeroPedido(
                        apenasNumerosInteiros(e.target.value)
                      )
                    }
                    onBlur={handleListaTransacaoDeCliente}
                    placeholder="Digite o número do pedido"
                  />
                </Form.Group>

                <Table bordered hover size="md">
                  <thead>
                    <tr>
                      <th>Transação</th>
                      <th>Pedido</th>
                      <th>Material</th>
                      <th>Quantidade</th>
                      {modoLista == "geral" && <th>Cliente</th>}
                      {modoLista == "cliente" && <th>Acumulado</th>}
                      <th>Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listaTransacoes &&
                      listaTransacoes?.map((transaction) => (
                        <tr key={transaction.transaction_id}>
                          <td>
                            <span>
                              {transaction.amount_operated > 0 ? (
                                <FiArrowDownCircle size={24} color="green" />
                              ) : (
                                <FiArrowUpCircle size={24} color="red" />
                              )}{" "}
                              {transaction.transaction_name}
                            </span>
                          </td>
                          <td>{transaction.order_code || "—"}</td>
                          <td>{transaction.material_name}</td>
                          <td
                            style={
                              transaction.amount_operated > 0
                                ? { color: "green" }
                                : { color: "red" }
                            }
                          >
                            {Number(transaction.amount_operated)}
                          </td>
                          {modoLista == "geral" && (
                            <td>{transaction.client_name}</td>
                          )}
                          {modoLista == "cliente" && (
                            <td>{Number(transaction.accumulated)}</td>
                          )}
                          <td>
                            {new Date(transaction.created_at).toLocaleString()}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div>
        </Tab>
        {utp == "interno" && (
           <Tab eventKey="emprestimo" title="Empréstimos">
           <Row className="g-3 mt-1">
             <Col xs="12">
               <Card className="card-one">
                 <Card.Body>
                   <CadastroEmprestimos handleFetch={handleFetch} />
                 </Card.Body>
               </Card>
             </Col>
           </Row>
           <Row className="g-3 mt-1">
             <Col xs="12">
               <Card className="card-one">
                 <Card.Body>
                   <ListaEmprestimo handleFetch={handleFetch} />
                 </Card.Body>
               </Card>
             </Col>
           </Row>
         </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default ContaPage;
