import { Card, Col, Row } from "react-bootstrap";
import { ListaEmprestimo } from "../../components/Emprestimos/Lista";
import { CadastroEmprestimos } from "../../components/Emprestimos/Cadastro";

export const ControleEmprestimos = () => {
  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <Col xl="12">
            <Card className="card-one">
              <Card.Header className="d-flex">
                <Card.Title as="h6" style={{ minWidth: "120px" }}>
                  <i class="ri-hand-coin-line"></i> Controle de empréstimos
                </Card.Title>
              </Card.Header>
            </Card>
          </Col>
        </Row>
        <Row className="g-3 mt-1">
          <Col xs="12">
            <Card className="card-one">
              <Card.Body>
                <CadastroEmprestimos />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="g-3 mt-1">
          <Col xs="12">
            <Card className="card-one">
              <Card.Body>
                <ListaEmprestimo />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
