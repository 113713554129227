import { useEffect, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";

import Select from "react-select";
import { userServices } from "../../apiServices/userServices";
import { useEmprestimo } from "../../Context/EmprestimoContext";

export const CadastroEmprestimos = ({handleFetch}) => {

  const {lancarEmprestimos, listarEmprestimos} = useEmprestimo();
  const token = localStorage.getItem("token");
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [emprestimoData, setEmprestimoData] = useState({
    cliente_id: "",
    material_id: "3",
    quantidade: 0,
  });

  const getUsers = async () => {
    const request = {
      token,
      page: 1,
      pageSearch: "client",
      searchKey: "",
      limit: 1000000,
    };
    const response = await userServices.getUsers(request);
    setUsers(
      response.data.users?.map((item) => ({
        ...item,
        value: item.ID,
        label: item.NAME,
      }))
    );
  };

  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setEmprestimoData({
      cliente_id: "",
      material_id: "3",
      quantidade: "",
    })

  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleCadastraEmprestimo = async () => {
    await lancarEmprestimos(emprestimoData);
    listarEmprestimos();
      handleClose()
      handleFetch()
  }

  return (
    <>
      <Button onClick={handleOpen}>Lançar emprestimo</Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Lançar empréstimo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <div className="d-md-flex align-items-center justify-content-between">
                <div className="w-100">
                  {users.length > 0 ? (
                    <Select
                      className="w-100"
                      classNamePrefix="form-control"
                      placeholder="Selecione"
                      noOptionsMessage={() =>
                        "Nenhum cliente encontrado com este nome"
                      }
                      options={users}
                      onChange={(e) =>
                        setEmprestimoData((prevData) => ({
                          ...prevData,
                          cliente_id: e?.ID,
                        }))
                      }
                    />
                  ) : (
                    <p>Carregando opções...</p>
                  )}
                </div>
              </div>
              <Form.Select value={emprestimoData.material_id}>
                <option value="3">Ouro 1000</option>
                <option value="1">Prata 950</option>
              </Form.Select>
              <Form.Control
                value={emprestimoData.quantidade}
                placeholder="Quantidade"
                type="number"
                step={0.001}
                onChange={(e) =>
                  setEmprestimoData((prevData) => ({
                    ...prevData,
                    quantidade: e.target.value,
                  }))
                }
                className="w-100"
              />
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} variant="danger" style={{ minWidth: "100px" }}>
            Cancelar
          </Button>
          <Button
          disabled={emprestimoData.cliente_id == "" || emprestimoData.quantidade == 0}
            onClick={handleCadastraEmprestimo}
            style={{ minWidth: "100px" }}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
