import { createContext, useState } from "react";
import { apiServices } from "../apiServices/ApiServices";
import OrderServices from "../apiServices/OrderServices";
import {
  swalErro,
  swalSucesso,
  toastErro,
  toastSucesso,
} from "../components/Swal";

export const OderUpdateStatus = () => {};

const token = localStorage.getItem("token");

export const UpdateOrderPre = async (data) => {
  const modalData = data.modalData;
  const orders = modalData.map((d) => d.ORDER || "");
  const dataReturn = {
    order: orders,
    token: data.token,
    status: 17,
    id_page: data.page + 1,
    description: `Atualização em massa!`,
  };

  return await apiServices.updateStatusOrder(dataReturn);
};

const OrderContext = createContext();

const OrderProvider = ({ children }) => {
  const [orderLog, setOrderLog] = useState([]);
  const [listOrders, setOrders] = useState([]);
  const [countOrderInitialStatus, setCountOrderInInitialStatus] = useState(0);

  const resetListOrders = () => {
    setOrders([]);
  };

  const orderList = async (data) => {
    const payload = {
      page: data.page,
      selectedUser: data.selectedUser,
      orderPage: data.order_page ? data.order_page : null,
      limit: data.limit,
      evolution_search: data.evolution_search,
      search: data.search,
      token: token,
      id_status: data.id_status,
    };
    const response = await OrderServices.OrderList(payload);
    setOrders(response.data.orders);
  };

  const orderAddFinalWeight = async (data) => {
    const payload = {
      action: "updateOrder",
      movimentation_insert: data.movimentation_insert,
      description: data.description,
      order_code: data.order_code,
      cobra_resina: data.cobra_resina,
      id_order: data.id_order,
      token: token,
      id_orders: data.id_orders,
      id_status: data.id_status,
      movimentation_type: data.movimentation_type,
      page: data.page,
      operation_code: data.operation_code,
      detail: data.detail,
      operation_type: data.operation_type,
      selectedUser: data.id_user,
      id_page: data.id_page,
      permission_code: data.permission_code,
      cobra_borracha: data.cobra_borracha,
      billing_type: data.billing_type,
      id_product: data.id_product,
      quantidade_borracha: data.quantidade_borracha
    };
    payload[data.key] = data.value;

    const response = await OrderServices.UpdateOrder(payload);
    if (response.data.status === "success") {
      toastSucesso(response.data.msg);
    } else {
      toastErro(response.data.msg);
    }
    return response;
  };
  const atualizaArvoreEmPedidos = async (data) => {
    const payload = {
      action: "atualiza_arvore_em_pedidos",
      tree: data.tree,
      order_codes: data.order_codes,
      token: token,
    };

    const response = await OrderServices.UpdateOrder(payload);
    if (response.data.status === "success") {
      toastSucesso(response.data.msg);
    } else {
      toastErro(response.data.msg);
    }
    return response;
  };

  const getDefectiveOrder = async (data) => {
    if (data.batch !== null || data.batch !== undefined) {
      const payload = {
        action: "get_defective_order",
        batch: data.batch,
        token: token,
      };
      const response = await OrderServices.GetDefectiveOrder(payload);
      return response;
    }
  };

  const OrderDetail = async (data) => {
    const payload = {
      order_code: data.order_code,
      token: token,
    };
    const response = await OrderServices.OrderDetail(payload);
    return response.data.order;
  };

  const getOurivesByOrder = async (data) => {
    const payload = {
      order_code: data.order_code,
      token: token,
    };
    const response = await OrderServices.getOurivesByOrder(payload);
    return response.data[0];
  };

  const getOrderLog = async (data) => {
    const payload = {
      action: "getOrderLog",
      order: data.order,
      token: token,
    };

    const response = await OrderServices.GetOrderLog(payload);
    setOrderLog(response.data);
  };

  const GetRequestPdf = async (data) => {
    const payload = {
      action: "pdf_request",
      id_request: data.id_request,
      token: token,
    };
    const response = await OrderServices.GetRequestPdf(payload);
    window.open(response.data.link, "_blank");
  };

  const handleUpdateOrderWeight = async (data) => {
    const { order_code, weight, field } = data;
    const payload = {
      action: "updateOrder",
      token,
      order_code,
    };
    payload[field] = weight;
    const response = await OrderServices.UpdateOrderWeight(payload);
    if (response.data.status === "success") {
      swalSucesso(response.data.msg);
    } else {
      swalErro(response.data.msg);
    }
  };

  const handleUpdateOrderAmount = async (data) => {
    const { order_code, amount } = data;
    const payload = {
      action: "update_amount_order",
      token,
      order_code,
      amount: amount,
    };

    const response = await OrderServices.UpdateOrderWeight(payload);
    if (response.data.status === "success") {
      swalSucesso(response.data.msg);
    } else {
      swalErro(response.data.msg);
    }
    return response;
  };

  const getCountOrderInInitialStatus = async () => {
    const payload = {
      action: "get_order_in_initial_status",
      token: token,
    };

    const response = await apiServices.getCountOrderInInitialStatus(payload);
    setCountOrderInInitialStatus(response.data);
  };

  const cancelOrder = async (data) => {
    const { order_code, cancel_reason } = data;
    const payload = {
      action: "cancel_order",
      token,
      order_code,
      cancel_reason,
    };

    const response = await OrderServices.cancelOrder(payload);
    if (response.data.status === "success") {
      swalSucesso(response.data.msg);
    } else {
      swalErro(response.data.msg);
    }
    return response;
  };

  const getDashboardData = async (data) => {
    const payload = {
      ...data,
      action: "get_dashboard_data",
      token: token,
    };

    const response = await OrderServices.getDashboardData(payload);
    return response;
  };

  return (
    <OrderContext.Provider
      value={{
        listOrders,
        resetListOrders,
        orderAddFinalWeight,
        orderList,
        OrderDetail,
        getDefectiveOrder,
        getOurivesByOrder,
        getOrderLog,
        orderLog,
        GetRequestPdf,
        handleUpdateOrderWeight,
        getCountOrderInInitialStatus,
        countOrderInitialStatus,
        cancelOrder,
        getDashboardData,
        handleUpdateOrderAmount,
        atualizaArvoreEmPedidos,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export { OrderContext, OrderProvider };
