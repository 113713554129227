import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";

import "./styles/styles.css";

import { ToastContainer, toast } from "react-toastify";

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  plugins,
  scales,
} from "chart.js";
import { Bar, Pie, PolarArea } from "react-chartjs-2";
import { OrderContext } from "../Context/OrderContext";
import { PrimeiraPagina } from "../components/ResumoExecutivo/PrimeiraPagina";
import { SegundaPagina } from "../components/ResumoExecutivo/SegundaPagina";
import { TerceiraPagina } from "../components/ResumoExecutivo/TerceiraPagina";
import ptBR from 'date-fns/locale/pt-BR'
import moment from "moment/moment";

import ReactDatePicker from "react-datepicker";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

export default function ResumoExecutivo() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const { getDashboardData } = useContext(OrderContext);
  const [dashboardData, setDashboardData] = useState([]);

  const [baseData] = useState({
    data_inicio: moment().clone().startOf("month").format("YYYY-MM-DD"),
    data_fim: moment().clone().endOf("month").format("YYYY-MM-DD"),
  });

  const [dashboardFiltro, setDashboardFiltro] = useState(baseData);


  const handleGetDashboardData = async () => {
    try {
      const response = await getDashboardData(dashboardFiltro);
      setDashboardData(response.data.data);
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  useEffect(() => {
    handleGetDashboardData();
  }, [dashboardFiltro]);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const [selectedTab, setSelectedTab] = useState(1);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 mb-0 p-lg-4">
        <Card className="card-one">
          <Card.Body>
            <div className="d-flex flex-row gap-3">
              <Form className="d-flex flex-row gap-3">
                <Form.Group>
                  <Form.Label htmlFor="startDatePicker">
                    <strong>Data de Início</strong>
                  </Form.Label>
                  <ReactDatePicker
                  locale={ptBR}
                    id="startDatePicker"
                    onChange={(date) => {
                      setDashboardFiltro((prevData) => ({
                        ...prevData,
                        data_inicio: moment(new Date(date)).format(
                          "YYYY-MM-DD"
                        ),
                      }));
                    }}
                    className="form-control"
                    selected={dashboardFiltro.data_inicio + " 00:00:00"}
                    dateFormat="dd/MM/yyyy"
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label htmlFor="endDatePicker">
                    <strong>Data de Fim</strong>
                  </Form.Label>
                  <ReactDatePicker
                  locale={ptBR}
                    id="endDatePicker"
                    onChange={(date) => {
                      setDashboardFiltro((prevData) => ({
                        ...prevData,
                        data_fim: moment(new Date(date)).format("YYYY-MM-DD"),
                      }));
                    }}
                    className="form-control"
                    selected={dashboardFiltro.data_fim + " 00:00:00"}
                    dateFormat="dd/MM/yyyy"
                  />
                </Form.Group>
              </Form>
            </div>
            <Row className="g-3 mb-0 d-flex flex-wrap">
              {selectedTab == 1 && (
                <PrimeiraPagina dashboardData={dashboardData} />
              )}
              {selectedTab == 2 && (
                <SegundaPagina dashboardData={dashboardData} />
              )}
              {selectedTab == 3 && (
                <TerceiraPagina dashboardData={dashboardData} />
              )}

              <div className="w-100 d-flex flex-row justify-content-center">
                <ButtonGroup>
                  <Button
                    onClick={() => setSelectedTab(1)}
                    variant={selectedTab == "1" ? "primary" : "outline-primary"}
                  >
                    1
                  </Button>
                  <Button
                    onClick={() => setSelectedTab(2)}
                    variant={selectedTab == "2" ? "primary" : "outline-primary"}
                  >
                    2
                  </Button>
                  <Button
                    onClick={() => setSelectedTab(3)}
                    variant={selectedTab == "3" ? "primary" : "outline-primary"}
                  >
                    3
                  </Button>
                </ButtonGroup>
              </div>

              {/* <iframe width="1920" height="1100" allowFullScreen="true" src="https://app.powerbi.com/view?r=eyJrIjoiZTk5YzlhNWUtMzU2MS00NjFlLWI3Y2YtN2U5MzQyMWZmNzY3IiwidCI6ImQwZGY3YTFjLWVjMzgtNDkwMi1hOTYyLTQyNWZkYjdlNWE5ZSJ9"></iframe> */}
            </Row>
            <Footer />
            <ToastContainer />
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
