import React, { useContext, useEffect } from "react";
import { Pagination } from "react-bootstrap";
import { NailingContext } from "../../Context/NailingContext/Context";

const PaginationComponent = ({
  currentPage,
  handlePageClick,
  className = "",
}) => {
  const { totalPages } = useContext(NailingContext);
  return (
    <Pagination
      className="w-100 d-flex justify-content-center mt-2"
      style={{ marginBottom: -5 }}
    >
      {Array.from({ length: totalPages }, (_, index) => {
        // Renderiza as páginas ou as elipses conforme a lógica
        if (
          index + 1 === currentPage ||
          index + 1 === 1 ||
          index + 1 === totalPages ||
          (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2)
        ) {
          return (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageClick(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          );
        } else if (
          (index + 1 === currentPage - 3 && currentPage > 5) ||
          (index + 1 === currentPage + 3 && currentPage < totalPages - 4)
        ) {
          return (
            <Pagination.Ellipsis
              key={index + 1}
              onClick={() => handlePageClick(index + 1)}
            />
          );
        }
        return null;
      })}
    </Pagination>
  );
};

export default PaginationComponent;
