import { createContext } from "react";
import { ScreenProvider } from "./Screen/context";
import { UserProvider } from "./UserContext";
import { OrderProvider } from "./OrderContext";
import { TreeProvider } from "./TreeContext";
import { AccountProvider } from "./AccountContext/AccountContext";
import { BalanceProvider } from "./BalanceContext/BalanceContext";
import { NailingProvider } from "./NailingContext/Context";
import { VisualizationProvider } from "./VisualizationContext/VisualizationContext";
import { LoadingProvider } from "./Loading/LoadingContext";
import { FoundryProvider } from "./FoundryContext/FoundryContext";
import { NotaProvider } from "./NotaContext/NotaContext";
import { TabelaMontagemProvider } from "./TabelaMontagemContext/TabelaMontagemContext";
import { EmprestimoProvider } from "./EmprestimoContext";

const AppContext = createContext();
const AppProvider = ({ children }) => {
  return (
    <AppContext.Provider value={{}}>
      <LoadingProvider>
        <ScreenProvider>
          <VisualizationProvider>
            <UserProvider>
              <OrderProvider>
                <NotaProvider>
                  <FoundryProvider>
                    <TreeProvider>
                      <AccountProvider>
                        <BalanceProvider>
                          <TabelaMontagemProvider>
                            <EmprestimoProvider>
                              <NailingProvider>{children}</NailingProvider>
                            </EmprestimoProvider>
                          </TabelaMontagemProvider>
                        </BalanceProvider>
                      </AccountProvider>
                    </TreeProvider>
                  </FoundryProvider>
                </NotaProvider>
              </OrderProvider>
            </UserProvider>
          </VisualizationProvider>
        </ScreenProvider>
      </LoadingProvider>
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
