import api from "./api";

const OrderServices = {
  OrderList: data => api.post("/orderController.php", {
    action: "read",
    page: data.page,
    selectedUser: data.selectedUser,
    order_page: data.orderPage ? data.orderPage : null,
    limit: data.limit,
    evolution_search: data.evolution_search,
    search: data.search,
    token: data.token,
    id_status: data.id_status,
  }),

  getDashboardData: data => (api.post("/orderController.php", data)),

  getOurivesByOrder: data => api.post("/orderController.php", {
    "action": "get_ourives_by_order",
    "token": data.token,
    "order_code": data.order_code
  }),
  UpdateOrder: data => api.post("orderController.php", data),

  OrderDetail: data => api.post("/orderController.php", {
    action: "read",
    page: data.page,
    selectedUser: data.selectedUser,
    order_page: data.orderPage,
    limit: data.limit,
    evolution_search: data.evolution_search,
    search: data.search,
    token: data.token,
    id_status: data.id_status,
    id_order: data.id_order,
  }),
  fundicaoOrder: (
    token,
    limit,
    page,
    search,
    id_status,
    orderPage,
    evolution_search,
    acabamento_user,
    idUser,
  ) => api.post("/orderController.php", {
    action: "get_foundry_order",
    page: page,
    selectedUser: idUser,
    order_page: orderPage,
    limit: limit,
    evolution_search: evolution_search,
    search: search,
    token: token,
    acabamento_user: acabamento_user,
    id_status: id_status,
  })
  ,
  prototipagemOrder: (
    token,
    limit,
    page,
    search,
    id_status,
    orderPage,
    evolution_search,
    acabamento_user,
    idUser,
    orderType
  ) => api.post("/orderController.php", {
    action: "read_prototipagem",
    page: page,
    selectedUser: idUser,
    order_page: orderPage,
    limit: limit,
    evolution_search: evolution_search,
    search: search,
    token: token,
    acabamento_user: acabamento_user,
    id_status: id_status,
    order_type: orderType,
  })
  ,

  GetDefectiveOrder: payload => api.post("/orderController.php", payload),
  GetOrdersNailing: payload => api.post("/orderController.php", payload),
  GetOrderLog: payload => api.post("/orderController.php", payload),
  GetRequestPdf: payload => api.post("/nailingController.php", payload),
  UpdateOrderWeight: paylaod => api.post("/orderController.php", paylaod),
  cancelOrder: payload => api.post("/orderController.php", payload),
};

export default OrderServices;
