import { useEffect } from "react";
import { useEmprestimo } from "../../Context/EmprestimoContext";
import { Button, ButtonGroup, Table } from "react-bootstrap";
import { numberFloating } from "../../functions/Mask";
import { dialog } from "../Swal";

export const ListaEmprestimo = ({handleFetch}) => {
  const { listaEmprestimos, listarEmprestimos, quitarEmprestimo } = useEmprestimo();
  useEffect(() => {
    listarEmprestimos();
  }, []);

  const handleQuitaEmprestimo = async (id) => {
    const confirm = await dialog("Deseja realmente acusar quitação deste empréstimo?")
    if(!confirm.isConfirmed){
      return
    }


    await quitarEmprestimo(id)

    listarEmprestimos();

    handleFetch();

  }
  return (
    <div>
      <Table bordered hover size="sm">
        <thead>
          <tr>
            <th>Cliente</th>
            <th>Material</th>
            <th>Quantidade (g)</th>
            <th>Data</th>
            <th>Situação</th>
            <th>Criado por</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {listaEmprestimos.map((item) => (
            <tr>
              <td>{item.client_name}</td>
              <td>
                {item.material_code} - {item.material}
              </td>
              <td>{numberFloating(item.amount_operated)}</td>
              <td>{new Date(item.created_at).toLocaleString()}</td>
              <td>
                {item.situation.charAt(0).toUpperCase() +
                  item.situation.substring(1)}
              </td>
              <td>{item.criado_por}</td>
              <td>
                <ButtonGroup>
                  <Button onClick={() => handleQuitaEmprestimo(item.id)} size="sm" disabled={item.situation == "quitado"}>Baixa</Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
