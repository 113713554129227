import { createContext, useContext, useState } from "react";
import { emprestimoService } from "../../apiServices/EmprestimosServices";
import {swalErro, swalSucesso} from '../../components/Swal'

const EmprestimoContext = createContext();

export const EmprestimoProvider = ({ children }) => {
  const token = localStorage.getItem("token");
  const [listaEmprestimos, setListaEmprestimos] = useState([]);

  const listarEmprestimos = async () => {
    const response = await emprestimoService({
      token: token,
      action: 'lista_emprestimos'
    });

    setListaEmprestimos(response.data.lista_emprestimos);
  }

  const lancarEmprestimos = async (emprestimoData) => {
    try {
      const response = await emprestimoService({
        token: token, 
        action: 'lancamento_emprestimo',
        ...emprestimoData
      })

      if(response.data.status == 'success'){
        swalSucesso(response.data.message);
        listarEmprestimos();
      }else{
        swalErro("Ocorreu um erro ao lançar emprestimo. Entre em contato com o suporte.");
      }
    } catch (error) {
      swalErro('Ocorreu um erro ao lançar emprestimo. Entre em contato com o suporte')
      console.error(error)
    }
  }

  const quitarEmprestimo = async (emprestimo_id) => {
    try {
      const response = await emprestimoService({
        "action" : "quita_emprestimo",
        "token": token,
        "emprestimo_id": emprestimo_id,
      })

      if(String(response.data.status)?.toLowerCase() == 'success'){
        swalSucesso(response.data.message);
        listarEmprestimos();
      }else{
        swalErro("Ocorreu um erro ao lançar emprestimo. Entre em contato com o suporte.");
      }
    } catch (error) {
      swalErro('Ocorreu um erro ao lançar emprestimo. Entre em contato com o suporte')
      console.error(error)
    }
  }

  return (
    <EmprestimoContext.Provider
      value={{
        listaEmprestimos,
        listarEmprestimos,
        lancarEmprestimos,
        quitarEmprestimo
      }}
    >
      {children}
    </EmprestimoContext.Provider>
  );
};

export const useEmprestimo = () => {
  return useContext(EmprestimoContext);
};
